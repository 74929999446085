import api from '@apiInstance';

export default {
  add (params) {
    return api.post('employees', params);
  },
  get (id) {
    return api.fetch('employees/' + id);
  },
  list (params) {
    return api.fetch('/employees', { params });
  },
  update (params) {
    return api.patch('employees', params);
  },
  delete (id) {
    return api.remove('employees', id);
  },
  resetPassword (id, password) {
    return api.request({
      url: '/employees/' + id + '/reset-password/',
      method: 'POST',
      body: {
        old_pass: password,
        password
      },
      type: 'update-password-request'
    });
  }
};
