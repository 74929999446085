import api from '@apiInstance';

export default {
  add (params) {
    return api.post('rfq_details', params);
  },
  get (id) {
    return api.fetch(`rfq_details/${id}/detail`);
  },
  async list (params) {
    const response = await api.fetch('/rfq_details', { params });
    return response;
  },
  update (params) {
    return api.patch('rfq_details', params);
  },
  delete (id) {
    return api.remove('rfq_details', id);
  }
};
