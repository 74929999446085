import api from '@apiInstance';

export default {
  add(data) {
    return api.post('jobrequest_headers', data);
  },
  get(id) {
    return api.fetch(`jobrequest_headers/${id}/detail`);
  },
  async list(params) {
    return await api.fetch('/jobrequest_headers', { params });
  },
  update(data) {
    return api.patch('jobrequest_headers', data);
  },
  delete(id) {
    return api.remove('jobrequest_headers', id);
  },

  createJobs(body) {
    return api.request({
      url: `/jobrequest_headers/${body.id}/create-jobs`,
      method: 'PATCH',
      body,
      type: 'service-request-create-jobs',
    });
  },

  saveJobs(body) {
    return api.request({
      url: `/jobrequest_headers/${body.id}/save-jobs`,
      method: 'PATCH',
      body,
      type: 'jobrequest_headers',
    });
  },

  saveQuotation(id) {
    return api.fetch(`jobrequest_headers/${id}/savetoquotations`);
  },
};
